// i18next-extract-mark-ns-start payments-glossary
import React from 'react';
import styled from 'styled-components';

import {PageProps, graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

import omnichannel from 'images/omnichannel.svg';

import {Content} from 'components/Content';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {SEO} from 'components/SEO';
import {ScreenSizes} from '../../types/responsive';
import {SignUpButton} from 'components/SignUpButton';
import {
  GradientBox,
  GradientCtaImage,
  GradientSectionDescription
} from 'components/landings/GradientBox';
import {AnchorLink} from 'components/AnchorLink';

const IndexBackground = styled.div`
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: -23%;
    height: 1256px;
    width: 1203px;
    background: linear-gradient(353.74deg, #41efd6 -47.57%, #aab4e9 13.85%);
    transform: matrix(2, 0, 0, -1, 1248, 4) rotate(47deg) skew(7deg, 1.6deg);
    @media (max-width: 1024px) {
      top: -32%;
      transform: matrix(2, 0, 0, -1, 1185, 25) rotate(44.5deg) skew(7deg, 1.6deg);
    }
    @media (max-width: 768px) {
      content: none;
    }
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    transform: rotate(34deg) matrix(1, 0, 0, -1, 459, -315);
    left: 50%;
    top: -6%;
    opacity: 1;
    height: 972px;
    width: 1289px;
    background: linear-gradient(353.74deg, #41efd6 -41.57%, #aab4e9 53.85%);
    @media (max-width: 1024px) {
      top: -35%;
      transform: rotate(34deg) matrix(1, 0, 0, -1, 470, -240);
    }
    @media (max-width: 768px) {
      content: none;
    }
  }
`;

const IndexContent = styled.div`
  text-align: center;
  padding-top: 100px;
  padding-bottom: 0px;
  min-height: 300px;
  @media (max-width: ${ScreenSizes.md}) {
    min-height: 310px;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    min-height: 210px;
  }
`;

const LetterContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
`;

const IndividualMarkedLetter = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
`;

const IndividualLetter = styled.div`
  font-size: 1.2rem;
  font-weight: 200;
  color: #333;
`;

const LetterCard = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: start;
  width: 30%;
  justify-content: left;
    
  @media (max-width: 580px) {
    width: 100%;
  }
`;

const BigLetter = styled.div`
  font-size: 3rem;
  font-weight: 500;
  color: #333;
`;

const ConceptsContainer = styled.div`
  margin-top: 20px;
`;

const ConceptLink = styled(AnchorLink)`
  cursor: pointer;
  text-decoration: none !important;
  color: #333;

  &:hover {
    color: #74ebd5;
  }
`;

const PaymentsGlossary: React.FC<PageProps> = (props) => {
  const {t, language} = useI18next();

  const alphabet = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
  ];

  const concepts = [
    {
      spanish: '2FA',
      english: '2FA (Double Factor Authentication)',
      url: ''
    },
    {
      spanish: '3D Secure',
      english: '3D Secure',
      url: 'https://monei.com/blog/what-is-3d-secure-and-its-advantages-for-e-commerce/'
    },
    {
      spanish: 'API de pago',
      english: 'Payments API',
      url: 'https://docs.monei.com/api/'
    },
    {
      spanish: 'App de pago con tarjeta',
      english: 'Card payment app',
      url: 'https://monei.com/blog/best-credit-card-payment-app/'
    },
    {
      spanish: 'Banco adquirente (o Adquirente)',
      english: 'Acquiring bank',
      url: 'https://monei.com/blog/acquiring-bank-vs-issuing-bank/'
    },
    {
      spanish: 'Banco Emisor (o Emisor)',
      english: 'Issuing bank',
      url: 'https://monei.com/blog/acquiring-bank-vs-issuing-bank/'
    },
    {
      spanish: 'Cartera digital',
      english: 'e-wallet',
      url: 'https://monei.com/blog/digital-wallet-ecommerce/'
    },
    {
      spanish: 'Checkout',
      english: 'Checkout',
      url: 'https://monei.com/blog/checkout/'
    },
    {
      spanish: 'Chip EMV',
      english: 'EMV chip',
      url: ''
    },
    {
      spanish: 'Comercio Headless',
      english: 'Headless commerce',
      url: 'https://monei.com/blog/headless-commerce-fabric/'
    },
    {
      spanish: 'Comisiones de pagos internacionales',
      english: 'Cross border fees',
      url: 'https://monei.com/blog/cross-border-fees/'
    },
    {
      spanish: 'Compra ahora paga después',
      english: 'BNPL',
      url: 'https://monei.com/blog/buy-now-pay-later/'
    },
    {
      spanish: 'Cross Selling',
      english: 'Cross Selling',
      url: ''
    },
    {
      spanish: 'Devolución',
      english: 'Refund',
      url: ''
    },
    {
      spanish: 'Disputa',
      english: 'Chargeback',
      url: 'https://monei.com/blog/chargebacks-prevention/'
    },
    {
      spanish: 'Ecommerce',
      english: 'Ecommerce',
      url: 'https://monei.com/blog/e-commerce-tips'
    },
    {
      spanish: 'Ecommerce internacional',
      english: 'International e-commerce',
      url: 'https://monei.com/blog/cross-border-ecommerce/'
    },
    {
      spanish: 'ERP',
      english: 'ERP',
      url: ''
    },
    {
      spanish: 'Errores de pago online',
      english: 'Online payment failures',
      url: 'https://monei.com/blog/online-payment-failure-message/'
    },
    {
      spanish: 'Euro digital',
      english: 'Digital Euro',
      url: 'https://monei.com/blog/bank-of-spain-financial-sandbox-monei/'
    },
    {
      spanish: 'Fintech',
      english: 'Fintech',
      url: 'https://monei.com/blog/fintech-vs-traditional-banks/'
    },
    {
      spanish: 'Fraude',
      english: 'Fraud',
      url: 'https://monei.com/blog/internet-fraud/'
    },
    {
      spanish: 'KYC',
      english: 'KYC',
      url: 'https://support.monei.com/hc/articles/18709966015505'
    },
    {
      spanish: 'LTV (Lifetime Value)',
      english: 'LTV (Lifetime Value)',
      url: 'https://monei.com/blog/ltv-lifetime-value/'
    },
    {
      spanish: 'M commerce',
      english: 'M-commerce',
      url: 'https://monei.com/blog/mobile-payment-processing/'
    },
    {
      spanish: 'Método de Pago',
      english: 'Payment method',
      url: 'https://monei.com/payment-methods/'
    },
    {
      spanish: 'Método de Pago Alternativo',
      english: 'Alternative payment method',
      url: 'https://monei.com/blog/alternative-payment-methods/'
    },
    {
      spanish: 'Método de Pago Local',
      english: 'Local payment method',
      url: 'https://monei.com/blog/local-payment-methods/'
    },
    {
      spanish: 'Near Field Communication (NFC)',
      english: 'Near Field Communication (NFC)',
      url: 'https://monei.com/blog/nfc-mobile-payments/'
    },
    {
      spanish: 'Orquestación de pagos',
      english: 'Payment orchestration',
      url: 'https://monei.com/blog/payment-orchestration-platform/'
    },
    {
      spanish: 'Página de pago alojada',
      english: 'Hosted payment page',
      url: 'https://docs.monei.com/docs/integrations/use-prebuilt-payment-page/'
    },
    {
      spanish: 'Pago entre Particulares',
      english: 'Peer-to-Peer Payment',
      url: 'https://monei.com/blog/types-of-payment-methods/'
    },
    {
      spanish: 'Pago internacional',
      english: 'International payments',
      url: 'https://monei.com/blog/cross-border-payments/'
    },
    {
      spanish: 'Pago móvil',
      english: 'Mobile payment',
      url: 'https://monei.com/blog/nfc-mobile-payments/'
    },
    {
      spanish: 'Pago por link',
      english: 'link payment',
      url: 'https://monei.com/features/pay-by-link/'
    },
    {
      spanish: 'Pagos con código QR',
      english: 'QR code payments',
      url: 'https://monei.com/blog/qr-code-payments/'
    },
    {
      spanish: 'Pagos Contactless',
      english: 'Contactless payments',
      url: 'https://monei.com/blog/contactless/'
    },
    {
      spanish: 'Pagos integrados',
      english: 'Integrated payments',
      url: 'https://monei.com/blog/integrated-payments/'
    },
    {
      spanish: 'pagos omnicanal',
      english: 'Omnichannel payments',
      url: 'https://monei.com/blog/omnichannel-payments/'
    },
    {
      spanish: 'Pagos Online',
      english: 'Online payments',
      url: 'https://monei.com/online-payments/'
    },
    {
      spanish: 'Pagos recurrentes',
      english: 'Recurring payments',
      url: 'https://monei.com/blog/what-is-recurring-payment/'
    },
    {
      spanish: 'Pagos sin efectivo',
      english: 'Cashless payments',
      url: 'https://monei.com/blog/cashless-payments/'
    },
    {
      spanish: 'PAN',
      english: 'PAN Card number',
      url: 'https://monei.com/blog/pan-number/'
    },
    {
      spanish: 'pagos sin fricciones',
      english: 'Frictionless Payments',
      url: 'https://monei.com/blog/frictionless-payments/'
    },
    {
      spanish: 'Pasarela de pagos',
      english: 'Payment Gateway',
      url: 'https://monei.com/blog/what-is-a-payment-gateway/'
    },
    {
      spanish: 'Payfac',
      english: 'Payfac',
      url: 'https://monei.com/blog/what-is-a-payfac/'
    },
    {
      spanish: 'PCI DSS',
      english: 'PCI DSS',
      url: 'https://monei.com/pci-dss/'
    },
    {
      spanish: 'Plataforma ecommerce',
      english: 'E-commerce Platform',
      url: 'https://monei.com/blog/best-ecommerce-platforms/'
    },
    {
      spanish: 'Procesador de Pagos',
      english: 'Payment Processor',
      url: 'https://monei.com/blog/payment-gateway-vs-payment-processor/'
    },
    {
      spanish: 'PSD2',
      english: 'PSD2',
      url: 'https://monei.com/blog/psd2/'
    },
    {
      spanish: 'PSP',
      english: 'PSP',
      url: 'https://monei.com/blog/what-is-a-psp/'
    },
    {
      spanish: 'Redes de tarjetas',
      english: 'credit card networks',
      url: 'https://monei.com/payment-methods/credit-cards/'
    },
    {
      spanish: 'SCA (Autenticación reforzada de clientes)',
      english: 'SCA (Strong Customer Authentication)',
      url: 'https://monei.com/blog/sca/'
    },
    {
      spanish: 'SEPA Adeudo directo',
      english: 'SEPA Direct debit',
      url: 'https://monei.com/blog/what-is-sepa/'
    },
    {
      spanish: 'Sku',
      english: 'Sku',
      url: ''
    },
    {
      spanish: 'Social commerce',
      english: 'Social commerce',
      url: ''
    },
    {
      spanish: 'Suscripciones',
      english: 'Subscriptions',
      url: 'https://monei.com/features/subscriptions/'
    },
    {
      spanish: 'SWIFT/BIC',
      english: 'SWIFT/BIC code',
      url: 'https://monei.com/blog/what-is-a-swift-bic-code/'
    },
    {
      spanish: 'Tap on phone',
      english: 'Tap on phone',
      url: 'https://monei.com/blog/tap-on-phone-payment/'
    },
    {
      spanish: 'Tap to Pay',
      english: 'Tap to Pay',
      url: 'https://monei.com/blog/tap-to-pay/'
    },
    {
      spanish: 'Tasa de intercambio',
      english: 'Interchange rate',
      url: 'https://monei.com/blog/what-are-interchange-fees/'
    },
    {
      spanish: 'Tokenización',
      english: 'Tokenization',
      url: 'https://monei.com/blog/what-is-tokenization-and-its-benefits-for-e-commerce/'
    },
    {
      spanish: 'TPV',
      english: 'POS',
      url: 'https://monei.com/monei-pay/retail-POS-system/'
    },
    {
      spanish: 'TPV móvil',
      english: 'mobile POS',
      url: 'https://monei.com/blog/mobile-pos-system-mpos/'
    },
    {
      spanish: 'TPV Virtual',
      english: 'TPV Virtual',
      url: 'https://monei.com/tpv-virtual/'
    }
  ];

  return (
    <>
      <SEO
        path="payments-glossary"
        title={t('Payments sector terminology')}
        description={t(
          'We provide you with the definitions of the most important terms in the payment and ecommerce industry. Get up to date with our glossary and grow your business.'
        )}
      />
      <IndexBackground>
        <Content>
          <IndexContent>
            <SectionHeader underline tagName="h1">
              <Trans>Payments sector glossary</Trans>
            </SectionHeader>
            <Trans parent="p">
              The following is a list of commonly used payments terminology. For your convenience,
              it’s sorted in alphabetical order. If you can’t find the definition you’re looking
              for, get in touch and we’ll add it.
            </Trans>
          </IndexContent>
        </Content>
      </IndexBackground>

      <Content>
        <Section>
          <LetterContainer>
            {alphabet.map((letter: string) => {
              if (
                concepts.filter((concept) =>
                  concept[language === 'en' ? 'english' : 'spanish'].startsWith(letter)
                ).length > 0
              ) {
                return <IndividualMarkedLetter>{letter}</IndividualMarkedLetter>;
              } else {
                return <IndividualLetter>{letter}</IndividualLetter>;
              }
            })}
          </LetterContainer>
        </Section>
      </Content>

      <Content>
        <Section>
          <LetterContainer>
            {alphabet.map((letter: string) => {
              const conceptsFiltered = concepts.filter((concept) =>
                concept[language === 'en' ? 'english' : 'spanish'].startsWith(letter)
              );
              if (conceptsFiltered.length > 0) {
                return (
                  <LetterCard>
                    <BigLetter>{letter}</BigLetter>
                    <ConceptsContainer>
                      {conceptsFiltered.map((concept) => {
                        const url =
                          language === 'es'
                            ? concept.url.replace('monei.com/', 'monei.com/es/')
                            : concept.url;
                        return (
                          <div>
                            <ConceptLink href={url}>
                              {concept[language === 'en' ? 'english' : 'spanish']}
                            </ConceptLink>
                          </div>
                        );
                      })}
                    </ConceptsContainer>
                  </LetterCard>
                );
              }
            })}
          </LetterContainer>
        </Section>
      </Content>

      <Content>
        <GradientBox
          wrapperStyle={{overflow: 'visible', marginTop: '100px'}}
          contentStyle={{maxWidth: {all: '650px', md: '100%'}}}>
          <SectionHeader tagName="h2" underline style={{color: 'white'}}>
            <Trans>Start accepting online payments today</Trans>
          </SectionHeader>
          <GradientSectionDescription>
            <Trans>
              Accept more payment methods, increase conversions, and grow your e-commerce business
              with the all-in-one payments platform.
            </Trans>
          </GradientSectionDescription>
          <SectionActions>
            <SignUpButton variant="dark">
              <Trans>Join MONEI Now</Trans>
            </SignUpButton>
          </SectionActions>
          <GradientCtaImage
            sx={{
              bottom: '00',
              right: '70px',
              width: '30%'
            }}
            src={omnichannel}
          />
        </GradientBox>
      </Content>
    </>
  );
};

export default PaymentsGlossary;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "payments-glossary"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
